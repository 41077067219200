import React from 'react';
import * as yup from 'yup';

import { TrashIcon } from '@heroicons/react/24/solid';

import { Button } from '@/components/Button';

import classNames from 'classnames';
import { InstanceProps } from './Modal';
import { ModalLayout } from './ModalLayout';

import backgroundImg from '@/assets/Vector.png';
import { Auth } from '@/containers/use-auth';
import { translations } from '@/locales';
import { apiClient } from '@/services/api';
import { authApiClient } from '@/services/auth-api';
import { FormattedMessage } from 'react-intl';
import { Form } from '../Form';
import { PasswordInput } from '../Form/PasswordInput';
import { ValidatedField } from '../Form/ValidatedField';
import { SubmitFeedback } from '../SubmitFeedback';

interface ModalProps extends InstanceProps<undefined, { subscriptionPlanId: string }> {
  title: React.ReactNode;
  subhead: React.ReactNode;
  isRequiredPass?: React.ReactNode;
  buttonMessage: React.ReactNode;
  onDeletion?(): Promise<void>;
}

export const schema = yup
  .object({
    password: yup.string().trim().required().label(translations.fields.password)
  })
  .required();

export const DeleteBasicModal: React.FC<ModalProps> = ({
  title,
  subhead,
  isRequiredPass = false,
  buttonMessage,
  onClose,
  onDeletion,
  className
}) => {
  const { context, loginWithPassword, logout } = Auth.useContainer();

  const onSubmit = React.useCallback(
    async ({ password }: yup.InferType<typeof schema>) =>
      authApiClient.authentication
        .loginWithPassword({ requestBody: { email: context!.email, password } })
        .then(async () => await apiClient.onboarding.deleteAccount().then(() => logout())),
    [context, loginWithPassword]
  );

  return (
    <ModalLayout className={classNames(className, 'max-w-modal py-0 px-0')}>
      <div className="flex flex-col items-center gap-0">
        <div
          className="flex w-full flex-col gap-4 bg-contain bg-no-repeat p-4"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundPosition: 'right bottom -55px',
            backgroundSize: '60% 130%'
          }}
        >
          <TrashIcon className="h-12 w-12 rounded-full bg-[#E7625E] p-3 text-white" />

          <div>
            <span className="text-xl font-bold capitalize">{title}</span>

            <p className="mt-2 text-xs font-normal">{subhead}</p>
            {isRequiredPass && <p className="mt-4 text-xs font-normal">To proceed, type your password</p>}
          </div>
        </div>

        {isRequiredPass ? (
          <Form {...{ className: 'w-full', onSubmit, schema }}>
            {({ submitting, submitError, submitSucceeded, dirtySinceLastSubmit }) => (
              <div className="flex w-full flex-col space-y-3.5">
                <div className="px-4">
                  <ValidatedField
                    field={PasswordInput}
                    id="password"
                    label={<FormattedMessage id={translations.fields.newPassword} />}
                    name="password"
                    placeholder="Password"
                    readOnly={submitting}
                    type="password"
                  />
                  <div className="mt-4">
                    <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
                  </div>
                </div>

                <div className=" flex w-full gap-4 rounded-b-lg bg-[#F9F9F9] p-4">
                  <Button
                    appearance="basicSecondary"
                    className="w-full hover:bg-gray-300"
                    onClick={onClose}
                    type="button"
                  >
                    Cancel
                  </Button>

                  <Button
                    appearance="deleteTertiary"
                    className="flex w-full items-center justify-center gap-1 border-[#DE3530] bg-transparent hover:border-transparent hover:bg-[rgba(231,98,94,0.24)]"
                    type="submit"
                  >
                    {buttonMessage}
                  </Button>
                </div>
              </div>
            )}
          </Form>
        ) : (
          <div className=" flex w-full gap-4 rounded-b-lg bg-[#F9F9F9] p-4">
            <Button appearance="basicSecondary" className="w-full hover:bg-gray-300" onClick={onClose} type="button">
              Cancel
            </Button>

            <Button
              appearance="deleteTertiary"
              className="flex w-full items-center justify-center gap-1 border-[#DE3530] bg-transparent hover:border-transparent hover:bg-[rgba(231,98,94,0.24)]"
              onClick={() => {
                onDeletion!()
                  .then(() => onClose())
                  .catch(() => null);
              }}
              type="button"
            >
              {buttonMessage}
            </Button>
          </div>
        )}
      </div>
    </ModalLayout>
  );
};
