import React from 'react';

import univerzIcon from '@/assets/univerz-icon.png';

export type Props = {
  bgColor: string;
  plan: React.ReactNode;
};

export const Card = ({ bgColor, plan }: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={'bg-gradient-diagonal h-20 w-40 rounded-md border bg-contain bg-no-repeat p-2'}
      style={{ backgroundColor: bgColor || '#BDBDBD' }}
    >
      <div className="flex flex-col items-end">
        <img alt="Univerz logo" className="w-5rem h-3 object-contain invert filter" src={univerzIcon} />
        <p className="text-xxs font-semibold text-white">{plan}</p>
      </div>
    </div>
  );
};
