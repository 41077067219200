import classnames from 'classnames';
import React from 'react';

import { AsComponent } from '@/components/As';

import { Input, Props as InputProps } from './Input';

export const Checkbox = React.forwardRef(function Checkbox<T extends AsComponent>(
  { label, className, ...props }: InputProps<T>,
  ref: React.Ref<any>
) {
  return (
    <div className={classnames('flex', className)}>
      <Input
        {...{ ref }}
        {...props}
        className={classnames(
          'form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600',
          props.disabled ? 'cursor-default' : 'cursor-pointer'
        )}
      />

      <label
        className={classnames(
          'ml-2 cursor-pointer select-none text-sm font-normal leading-5 text-gray-700',
          props.disabled ? 'cursor-default' : 'cursor-pointer',
          className
        )}
        htmlFor={props.id}
      >
        {label}
      </label>
    </div>
  );
});
