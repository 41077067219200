import { CreateSurveySchemaType, FormCreateSurvey } from '@/components/FormCreateSurvey';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { Navbar } from '@/components/Navbar';
import { useRole } from '@/hooks/useRole';
import { translations } from '@/locales';
import { apiClient } from '@/services/api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

export const SurveyCreatePage = () => {
  const { community } = useRole();
  const navigate = useNavigate();

  const onSubmit = React.useCallback(
    async (values: CreateSurveySchemaType) => {
      await apiClient.survey.createSurvey({
        requestBody: {
          ...values,
          communityId: community!.id,
          // @ts-ignore
          questions: values.questions.map((question) => ({
            ...question,
            audience: {}
          }))
        }
      });

      navigate(`/surveys`);
    },
    [navigate, community]
  );

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-[6px]">
          <Link
            as={RouterLink}
            className="text-neutral-gray-400 text-base font-medium"
            to={`/communities/${community?.id}`}
          >
            <FormattedMessage id={translations.pages.profile.myCommunity} />
          </Link>

          <span className="text-neutral-gray-400 text-base">/</span>

          <Link
            as={RouterLink}
            className="text-neutral-gray-400 text-base font-medium"
            to={`/communities/${community?.id}?tab=Surveys`}
          >
            <FormattedMessage id={translations.pages.surveys.surveys} />
          </Link>

          <span className="text-neutral-gray-400 text-base">/</span>

          <Link
            as={RouterLink}
            className="text-brand-primary-contrast text-base font-medium"
            to={`/communities/surveys/create`}
          >
            <FormattedMessage id={translations.buttons.newSurvey} />
          </Link>
        </div>
        <Navbar title="" />
      </div>

      <FormCreateSurvey onSubmit={onSubmit} />
    </Layout>
  );
};
