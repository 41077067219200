import { CreateSurveySchemaType, FormCreateSurvey } from '@/components/FormCreateSurvey';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { Navbar } from '@/components/Navbar';
import { Tab } from '@/components/TabNew';
import { useRole } from '@/hooks/useRole';
import { translations } from '@/locales';
import { apiClient } from '@/services/api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

export const SurveyDetailsPage = () => {
  const { community } = useRole();
  const navigate = useNavigate();
  const { surveyId } = useParams();

  if (!surveyId) navigate('/surveys');

  const {
    isLoading: surveyLoading,
    error: surveyError,
    data: surveyData
  } = useSWR(['get-survey-details', { communityId: community!.id }], () =>
    apiClient.survey.getSurveyDetails({
      communityId: community!.id,
      surveyId: surveyId!
    })
  );

  const onSubmit = React.useCallback(
    async (values: CreateSurveySchemaType) => {
      await apiClient.survey.updateSurvey({
        communityId: community?.id,
        requestBody: {
          ...values,
          communityId: community!.id,
          // @ts-ignore
          questions: values.questions.map((question) => ({
            ...question,
            audience: {}
          }))
        },
        surveyId: surveyId!
      });

      navigate(`/communities/${community?.id}?tab=Surveys`);
    },
    [navigate, community, surveyId]
  );

  const onDeleteSurvey = async () => {
    if (!surveyData) return;

    await apiClient.survey.deleteSurvey({ communityId: community?.id, surveyId: surveyData.id });
    navigate(`/communities/${community?.id}?tab=Surveys`);
  };

  const onSuspendSurvey = async () => {
    if (!surveyData) return;

    await apiClient.survey.suspendSurvey({ communityId: community?.id, surveyId: surveyData.id });
    navigate(`/communities/${community?.id}?tab=Surveys`);
  };

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-[6px]">
          <Link
            as={RouterLink}
            className="text-neutral-gray-400 text-base font-medium"
            to={`/communities/${community?.id}`}
          >
            <FormattedMessage id={translations.pages.profile.myCommunity} />
          </Link>

          <span className="text-neutral-gray-400 text-base">/</span>

          <Link
            as={RouterLink}
            className="text-neutral-gray-400 text-base font-medium"
            to={`/communities/${community?.id}?tab=Surveys`}
          >
            <FormattedMessage id={translations.pages.surveys.surveys} />
          </Link>

          <span className="text-neutral-gray-400 text-base">/</span>

          <Link
            as={RouterLink}
            className="text-brand-primary-contrast text-base font-medium"
            to={`/communities/surveys/create`}
          >
            <FormattedMessage id={translations.buttons.editSurvey} />
          </Link>
        </div>
        <Navbar title="" />
      </div>

      <Tab.Root>
        <Tab.List>
          <Tab.Label>Edit</Tab.Label>
          <Tab.Label>Responses</Tab.Label>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <LoadingOverlay error={surveyError} loading={surveyLoading}>
              <FormCreateSurvey
                initialValues={{
                  ...surveyData,
                  audiences: surveyData
                    ? {
                        groups: {
                          added: [],
                          allAdded: false,
                          removed: []
                        },
                        members: {
                          added: surveyData.audience ?? [],
                          allAdded: false,
                          removed: []
                        }
                      }
                    : undefined,
                  //@ts-ignore
                  questions:
                    surveyData?.questions?.map((question) => ({
                      options: question?.options ?? [],
                      text: question.text
                    })) ?? []
                }}
                onDelete={onDeleteSurvey}
                onSubmit={onSubmit}
                onSuspend={onSuspendSurvey}
              />
            </LoadingOverlay>
          </Tab.Panel>
          <Tab.Panel>Responses</Tab.Panel>
        </Tab.Panels>
      </Tab.Root>
    </Layout>
  );
};
