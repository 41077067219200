import React from 'react';

import { AsComponent, AsProps } from '@/components/As';
import { Input } from './Input';

export type Props<T extends AsComponent> = AsProps<T> & {
  invalid?: boolean;
};

export const ColorInput = React.forwardRef(function ColorInput<T extends AsComponent = 'input'>(
  { label = 'Cor', ...props }: React.PropsWithChildren<Props<T> & { label?: string }>,
  ref: React.Ref<any>
) {
  return (
    <label className="flex w-full cursor-pointer flex-col items-center">
      <div className="flex gap-2">
        <span className="h-6 w-6 rounded-full" style={{ backgroundColor: props.value || '#BDBDBD' }}></span>
        <span className="text-gray-700">{label}</span>
      </div>
      <Input
        {...props}
        ref={ref}
        style={{
          height: 0,
          opacity: 0,
          pointerEvents: 'none',
          position: 'absolute',
          width: 0
        }}
      />
    </label>
  );
});
