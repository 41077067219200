import { Tab as TabPrimitive } from '@headlessui/react';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

const List = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <TabPrimitive.List className={classNames('flex gap-6', className)}>{children}</TabPrimitive.List>
);

const Label = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <TabPrimitive
    className={classNames(
      'ui-selected:border-brand-primary-contrast ui-selected:text-brand-primary-contrast border-neutral-gray-100 text-neutral-black border-b-2 py-2 px-4 text-base',
      className
    )}
  >
    {children}
  </TabPrimitive>
);

export const Tab = {
  Label,
  List,
  Panel: TabPrimitive.Panel,
  Panels: TabPrimitive.Panels,
  Root: TabPrimitive.Group
};
