import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Layout } from '@/components/Layout';

import { Button } from '@/components/Button';
import { Navbar } from '@/components/Navbar';
import { Tab } from '@/components/Tab';
import { SubscriptionPlans } from '@/pages/SubscriptionPlans/SubscriptionPlans';
import { useLocation } from 'react-router';
import { SurveysPage } from '../Surveys/SurveysPage';

const tabs = [
  { component: <div>Posts</div>, label: 'Posts' },
  { component: <div>Events</div>, label: 'Events' },
  { component: <SurveysPage />, label: 'Surveys' },
  { component: <div>General</div>, label: 'General' },
  { component: <div>Membership questions</div>, label: 'Membership questions' },
  { component: <SubscriptionPlans />, label: 'Account' }
];

export const MyCommunityPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamTab = searchParams.get('tab');
  const foundTab = tabs.find((tab) => tab.label === searchParamTab);
  const [activeTab, setActiveTab] = useState(foundTab ? foundTab.label : tabs[0].label);
  const [activeTabComponent, setActiveTabComponent] = useState(foundTab ? foundTab.component : tabs[0].component);

  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
    const activeTab = tabs.find((tab) => tab.label === tabName);
    setActiveTabComponent(activeTab!.component);
  };

  return (
    <Layout>
      <Navbar title={<FormattedMessage id={translations.pages.profile.myCommunity} />} />

      <div className="flex w-full flex-row items-center justify-between gap-6">
        <Tab activeTab={activeTab} onTabChange={handleTabChange} tabs={tabs.map((tab) => tab.label)} />

        <Button
          appearance="basicSecondary"
          onClick={() => {
            console.log('TODO: IMPLEMENT');
          }}
        >
          <FormattedMessage id={translations.pages.profile.previewCommunityOnApp} />
        </Button>
      </div>

      {activeTabComponent}
    </Layout>
  );
};
