/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserPageDto } from '../models/UserPageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return a page of users.
   * @returns UserPageDto Returns a page of users.
   * @throws ApiError
   */
  public findUsers({
status,
searchQuery,
pageSize,
pageNumber,
}: {
/**
 * Status to filter by.
 */
status?: 'active' | 'inactive' | 'pending',
/**
 * text to search by.
 */
searchQuery?: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<UserPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/find',
      query: {
        'status': status,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        403: `The action is not allowed.`,
      },
    });
  }

}
