/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PlanDto {
  GOLD = 'gold',
  SILVER = 'silver',
  BRONZE = 'bronze',
  FREE = 'free',
}
