import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';

import { Button } from '@/components/Button';
import { translations } from '@/locales';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from '../../components/Dropdown';

import deletePath from '@/assets/images/delete.svg';
import edit from '@/assets/images/edit.svg';
import verticalDotsPath from '@/assets/images/vertical-dots.svg';
import { RemoveBankAccountModal } from '@/components/Modal/RemoveBankAccountModal';
import { Column, DataTable } from '@/components/Table/DataTable';
import { useModal } from '@/hooks/useModal';
import { apiClient } from '@/services/api';
import { SubscriptionPlanDto } from '@billy/management-api-sdk';
import { useNavigate, useParams } from 'react-router';
import useSWR, { mutate } from 'swr';
import { Card } from '../../components/Card';
import { Input } from '../../components/Form/Input';
import { Select } from '../../components/Form/Select';
import { Modal } from '../../components/Modal';
import { DeleteBasicModal } from '../../components/Modal/DeleteBasicModal';

export const SubscriptionPlans = () => {
  const [selectedSubsPlansId, setSelectedSubsPlansId] = React.useState('');
  const { communityId } = useParams();
  const navigate = useNavigate();

  const removeAccountModal = useModal(DeleteBasicModal);
  const warningRemoveAccountModal = useModal(RemoveBankAccountModal);
  const deleteSubscriptionPlanModal = useModal(DeleteBasicModal);

  const { data: subscriptionPlans = [] } = useSWR(
    communityId ? ['subscriptionPlans', communityId] : null,
    async () =>
      await apiClient.subscriptionPlan.findAllSubscriptionPlans({
        communityId: communityId!
      })
  );

  const [bankValue, setBankValue] = useState<string | undefined>('bankId_123');
  const [showBenefits, setShowBenefits] = useState(false);
  const [subPlanShowBenefitsId, setSubPlanShowBenefitsId] = useState('');

  const handleDeleteSubsPlan = async () => {
    await apiClient.subscriptionPlan
      .deleteSubscriptionPlan({
        communityId: communityId!,
        requestBody: { subscriptionPlanId: selectedSubsPlansId }
      })
      .then(() => {
        mutate(
          ['subscriptionPlans', communityId],
          subscriptionPlans.filter((subsPlan) => subsPlan.id !== selectedSubsPlansId),
          false
        );
      });
  };

  const columns: Column<SubscriptionPlanDto>[] = [
    {
      accessor: 'Plan',
      cell: (subscriptionPlan: SubscriptionPlanDto) => (
        <div className={`flex items-center gap-4`}>
          <Card bgColor={subscriptionPlan.cardColor} plan={subscriptionPlan.name} />
          <p>{subscriptionPlan.name}</p>
        </div>
      ),
      header: 'Plan'
    },
    {
      accessor: 'Annual price',
      cell: (subscriptionPlan: SubscriptionPlanDto) => (
        <p>
          {subscriptionPlan.plan == 'Paid'
            ? `USD ${subscriptionPlan.annualPrice.toFixed(2).replace('.', ',')}`
            : 'Free'}
        </p>
      ),
      header: 'Annual price'
    },
    {
      accessor: 'Benefits',
      cell: (subscriptionPlan: SubscriptionPlanDto) => {
        return (
          <div className="relative inline-block">
            <p
              className={subscriptionPlan.benefits.length ? 'cursor-pointer text-[#ED6F4C]' : ''}
              onClick={() => {
                if (subscriptionPlan.benefits.length) {
                  setShowBenefits(!showBenefits);
                  setSubPlanShowBenefitsId(subscriptionPlan.id);
                }
              }}
            >
              {subscriptionPlan.benefits.length == 1
                ? '1 Benefit'
                : subscriptionPlan.benefits.length
                ? `${subscriptionPlan.benefits.length} Benefiits`
                : 'No benefits'}
            </p>

            {showBenefits && subPlanShowBenefitsId == subscriptionPlan.id && (
              <div className="absolute left-1/2 z-10 mt-2 -translate-x-1/2 transform rounded-lg border border-gray-300 bg-white p-4 text-black">
                <div
                  className="absolute left-1/2 -top-2 h-0 w-0 -translate-x-1/2 transform border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent"
                  style={{ borderTopColor: 'white' }}
                ></div>
                <div className="flex w-max max-w-sm flex-wrap gap-4">
                  {subscriptionPlan.benefits.map((benefit) => (
                    <p className="rounded-full bg-gray-300 py-1 px-2 text-gray-800" key={benefit.id}>
                      {benefit.name}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      },
      header: 'Benefits'
    },
    {
      accessor: 'actions',
      cell: (subscriptionPlan: SubscriptionPlanDto) => (
        <Dropdown
          appearance="filter"
          dropdownClassName="absolute mt-2 w-36 rounded-md bg-white shadow-lg z-10 border border-gray-200 right-0 top-full"
          shouldUseShadow={false}
          svgPath={verticalDotsPath}
          trigger={
            <Button appearance="filter" className="rounded-full hover:bg-gray-100" shouldUseShadow={false}>
              <img alt="vertical dots" className="h-5 w-5" src={verticalDotsPath} />
            </Button>
          }
        >
          <Button
            className="flex w-full items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200"
            onClick={() => navigate(`/subscriptionPlan/${subscriptionPlan.id}`)}
          >
            <div className="flex items-center gap-2">
              <img alt="edit" className="h-7 w-7" src={edit} />
              <FormattedMessage id={translations.buttons.edit} />
            </div>
          </Button>

          <Button
            className="flex w-full items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200"
            onClick={() => {
              setSelectedSubsPlansId(subscriptionPlan.id);
              deleteSubscriptionPlanModal.open().catch(() => null);
            }}
          >
            <div className="flex items-center gap-2">
              <img alt="delete" className="h-7 w-7" src={deletePath} />
              <FormattedMessage id={translations.buttons.delete} />
            </div>
          </Button>
        </Dropdown>
      ),
      header: ''
    }
  ];

  return (
    <div className="flex w-full flex-col items-center">
      <div className="mt-6 flex w-full max-w-2xl items-center justify-between">
        <p className="text-xl font-semibold">Subscription plans</p>
        <Button
          appearance="primary"
          disabled={subscriptionPlans.length >= 5}
          onClick={() => navigate('/subscriptionPlan/new')}
        >
          + Add plan ({subscriptionPlans.length}/5)
        </Button>
      </div>

      <div className="mt-6 flex w-full max-w-2xl items-center">
        <DataTable columns={columns} data={subscriptionPlans || []} />
      </div>

      <div className="mt-16 flex w-full max-w-2xl flex-col items-center">
        <div className="flex flex-col gap-4">
          <div className="flex w-full items-center justify-between">
            <p className="text-xl font-semibold">Bank account</p>
            <Button
              appearance="deleteTertiary"
              className=" rounded-lg border-[#DE3530] bg-transparent hover:border-transparent hover:bg-[rgba(231,98,94,0.24)]"
              onClick={() => {
                if (!subscriptionPlans.some((subPlan) => subPlan.plan == 'Free')) {
                  warningRemoveAccountModal.open().catch(() => null);
                } else {
                  removeAccountModal.open().catch(() => null);
                }
              }}
              type="submit"
            >
              Remove account
            </Button>
          </div>
          <p className="text-base text-gray-900">
            <FormattedMessage id={translations.pages.subscritionPlans.bankAccount.message} />
          </p>
          <label className="flex w-full cursor-pointer flex-col rounded-xl border border-gray-200 px-2 pt-1">
            <p className="text-xs text-gray-600"> Account Holder Name </p>
            <Input
              className="my-2 h-0 rounded-none border-0 bg-transparent px-0 py-2
            shadow-none"
              defaultValue="David Patel"
              onBlur={() => console.log('TODO: save changes account bank')}
            />
          </label>
          <label className="flex w-full cursor-pointer flex-col rounded-xl border border-gray-200 px-2 pt-1">
            <p className="text-xs text-gray-600"> Bank </p>
            <Select
              className="my-2 h-0 rounded-none border-none bg-transparent py-2 pl-0 pr-0 shadow-none"
              id="bankId"
              items={[
                { name: 'Bank of America', value: 'bankId_123' },
                { name: 'Bank of Europe', value: 'bankId_456' }
              ]}
              name="bankId"
              onBlur={() => console.log('TODO: save changes account bank')}
              onChange={(value: string) => setBankValue(value)}
              placeholder="Select your bank"
              searchable
              value={bankValue}
            />
          </label>
          <label className="flex w-full cursor-pointer flex-col rounded-xl border border-gray-200 px-2 pt-1">
            <p className="text-xs text-gray-600">Account Number</p>
            <Input
              className="my-2 h-0 rounded-none border-0 bg-transparent px-0 py-2
            shadow-none"
              defaultValue="12345678"
              onBlur={() => console.log('TODO: save changes account bank')}
            />
          </label>
        </div>

        <div className="mt-16 flex flex-col gap-2">
          <p className="text-xl font-semibold">Delete community</p>
          <p className="text-base text-gray-900">
            <FormattedMessage id={translations.pages.subscritionPlans.deleteCommunity.message} />{' '}
            <span className="text-[#4AB1B5]">
              (<FormattedMessage id={translations.pages.subscritionPlans.deleteCommunity.email} />)
            </span>
          </p>
        </div>

        <div className="mt-8 flex flex-col gap-1">
          <p className="text-base font-semibold">Suspended community</p>
          <p className="text-sm text-gray-900">
            <FormattedMessage id={translations.pages.subscritionPlans.deleteCommunity.suspend} />
          </p>
        </div>

        <div className="mt-4 flex flex-col gap-1">
          <p className="text-base font-semibold">Delete community</p>
          <p className="text-sm text-gray-900">
            <FormattedMessage id={translations.pages.subscritionPlans.deleteCommunity.delete} />
          </p>
        </div>

        <div className="mt-8 mb-16 flex items-center gap-2 rounded-lg bg-[#FEF7E0] p-2">
          <ExclamationTriangleIcon className="h-8 w-12 rounded-full bg-[#F1A660] p-1 text-white" />
          <p className="text-base text-gray-900">
            <FormattedMessage id={translations.pages.subscritionPlans.deleteCommunity.alert} />
          </p>
        </div>
      </div>

      <Modal
        buttonMessage={'Delete'}
        onDeletion={handleDeleteSubsPlan}
        subhead={<FormattedMessage id={translations.pages.subscritionPlans.modalSubhead} />}
        title={<FormattedMessage id={translations.pages.subscritionPlans.modalTitle} />}
        {...deleteSubscriptionPlanModal.props}
      />

      <Modal
        buttonMessage={'Delete'}
        onDeletion={async () => console.log('deletando account')}
        subhead={<FormattedMessage id={translations.pages.subscritionPlans.bankAccount.modalSubhead} />}
        title={<FormattedMessage id={translations.pages.subscritionPlans.bankAccount.modalTitle} />}
        {...removeAccountModal.props}
      />

      <Modal
        subhead="Change the subscription method before removing bank details"
        title="Change the subscription method before removing bank details"
        {...warningRemoveAccountModal.props}
      />
    </div>
  );
};
