/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BenefitCategoriesDto } from '../models/BenefitCategoriesDto';
import type { BenefitDto } from '../models/BenefitDto';
import type { BenefitPageDto } from '../models/BenefitPageDto';
import type { CreateBenefitDto } from '../models/CreateBenefitDto';
import type { UploadIntentDto } from '../models/UploadIntentDto';
import type { UploadRequestBody } from '../models/UploadRequestBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BenefitService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Return a page of benefits.
   * @returns BenefitPageDto Returns a page of benefits.
   * @throws ApiError
   */
  public findBenefits({
categoryId,
communityId,
searchQuery,
pageSize,
pageNumber,
}: {
/**
 * Category to filter by.
 */
categoryId?: string,
/**
 * Community to filter by.
 */
communityId?: string,
/**
 * text to search by.
 */
searchQuery?: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<BenefitPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/benefits/find',
      query: {
        'categoryId': categoryId,
        'communityId': communityId,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Create benefit.
   * @returns BenefitDto Returns the created benefit.
   * @throws ApiError
   */
  public createBenefit({
communityId,
requestBody,
}: {
/**
 * Community to filter by.
 */
communityId: string,
requestBody: CreateBenefitDto,
}): CancelablePromise<BenefitDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/benefits/create',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return a benefit.
   * @returns BenefitDto Returns the benefit.
   * @throws ApiError
   */
  public getBenefitDetails({
benefitId,
}: {
/**
 * The id of the benefit.
 */
benefitId: string,
}): CancelablePromise<BenefitDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/benefits/get-details',
      query: {
        'benefitId': benefitId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Update a benefit.
   * @returns BenefitDto Returns the updated benefit.
   * @throws ApiError
   */
  public updateBenefitDetails({
benefitId,
requestBody,
}: {
/**
 * The id of the benefit.
 */
benefitId: string,
requestBody: CreateBenefitDto,
}): CancelablePromise<BenefitDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/benefits/update-details',
      query: {
        'benefitId': benefitId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Delete a benefit.
   * @returns void 
   * @throws ApiError
   */
  public deleteBenefit({
benefitId,
}: {
/**
 * The id of the benefit.
 */
benefitId: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/benefits/delete',
      query: {
        'benefitId': benefitId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Initiate benefit logo upload.
   * @returns UploadIntentDto Returns an upload intent.
   * @throws ApiError
   */
  public initiateBenefitLogoUpload({
communityId,
requestBody,
}: {
/**
 * The id of the community.
 */
communityId: string,
/**
 * Only `image/jpeg`, `image/png` are accepted as mimeType.
 */
requestBody: UploadRequestBody,
}): CancelablePromise<UploadIntentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/benefits/initiate-logo-upload',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return the list of categories.
   * @returns BenefitCategoriesDto Returns the benefit categories.
   * @throws ApiError
   */
  public getBenefitCategories({
communityId,
}: {
/**
 * The id of the community.
 */
communityId: string,
}): CancelablePromise<BenefitCategoriesDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/benefits/get-categories',
      query: {
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

}
