import classnames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from './As';

export type Appearance =
  | 'primary'
  | 'secondary'
  | 'basic'
  | 'basicSecondary'
  | 'delete'
  | 'deleteSecondary'
  | 'deleteTertiary'
  | 'oldPrimary'
  | 'filter'
  | 'filterIcon'
  | 'success'
  | 'tertiary';

const styles: Record<Appearance, string> = {
  basic: 'relative border-grey-300 border text-gray-700 hover:bg-gray-50 disabled:bg-gray-200',
  basicSecondary: 'relative bg-[#EEEEEE] text-black disabled:bg-gray-300',
  delete: 'relative bg-red-600  hover:bg-red-500 text-white disabled:bg-gray-200',
  deleteSecondary: 'relative border-grey-300 border text-[#FF231C] hover:bg-gray-50 disabled:bg-gray-200',
  deleteTertiary: 'relative border-grey-300 border bg-[rgba(231,98,94,0.24)] text-[#DE3530] disabled:bg-gray-200',
  filter: 'relative bg-transparent text-gray-700 disabled:bg-gray-200',
  filterIcon:
    'relative bg-transparent text-gray-700 disabled:bg-gray-200 flex items-center justify-center w-fit h-fit p-0',
  oldPrimary: 'relative bg-indigo-600 text-white hover:bg-indigo-500 disabled:bg-indigo-300',
  primary: 'relative bg-[#FEA68A] text-black hover:bg-indigo-100 hover:text-indigo-700 disabled:bg-gray-300',
  secondary: 'relative bg-indigo-50 hover:bg-indigo-100 text-indigo-700 disabled:bg-gray-300',
  success: 'relative bg-[rgba(82,182,66,0.24)] text-[#317D25] disabled:bg-gray-300',
  tertiary: 'relative bg-transparent text-[#ED6F4C]'
};

export interface Props {
  appearance?: Appearance;
  shouldUseShadow?: boolean;
}

export const Button = React.forwardRef(
  <T extends AsComponent>(
    {
      // @ts-ignore
      as = 'button',
      appearance,
      disabled,
      shouldUseShadow = true,
      className,
      children,
      onClick,
      ...rest
    }: Props & AsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => (
    <As
      {...{ as, disabled, ref }}
      className={classnames(
        className,
        'rounded-md py-2 px-4 text-sm font-medium leading-5 duration-300',
        !!appearance && styles[appearance],
        shouldUseShadow && 'shadow-sm'
      )}
      onClick={disabled ? null : onClick}
      {...rest}
    >
      {children}
    </As>
  )
);
