import React from 'react';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { MemberDto } from '@billy/management-api-sdk';

import classNames from 'classnames';
import { InstanceProps } from './Modal';
import { ModalLayout } from './ModalLayout';

import backgroundImg from '@/assets/Vector.png';
import { translations } from '@/locales';

interface ModalProps extends InstanceProps<undefined, Pick<MemberDto, 'email' | 'firstName' | 'id'>> {
  title: React.ReactNode;
  subhead: React.ReactNode;
}

export const schema = yup
  .object({
    password: yup.string().trim().required().label(translations.fields.password)
  })
  .required();

export const RemoveBankAccountModal: React.FC<ModalProps> = ({ title, subhead, onClose, className }) => {
  return (
    <ModalLayout className={classNames(className, 'max-w-md py-0 px-0')}>
      <div className="flex flex-col items-center gap-0">
        <div
          className="flex w-full flex-col gap-4 bg-contain bg-no-repeat p-4"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundPosition: 'right bottom -55px',
            backgroundSize: '60% 130%'
          }}
        >
          <div>
            <span className="text-2xl font-bold capitalize">{title}</span>

            <p className="text-md mt-2 font-normal">{subhead}</p>
          </div>
        </div>

        {
          <div className=" flex w-full gap-4 rounded-b-lg bg-[#F9F9F9] p-4">
            <Button appearance="primary" className="w-full" onClick={onClose}>
              Close
            </Button>
          </div>
        }
      </div>
    </ModalLayout>
  );
};
