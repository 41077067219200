/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSubscriptionPlanDto } from '../models/CreateSubscriptionPlanDto';
import type { SubscriptionPlanDto } from '../models/SubscriptionPlanDto';
import type { SubscriptionPlanIdDto } from '../models/SubscriptionPlanIdDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SubscriptionPlanService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create a subscription plan.
   * @returns SubscriptionPlanDto Returns the created subscription plan.
   * @throws ApiError
   */
  public createSubscriptionPlan({
requestBody,
}: {
requestBody: CreateSubscriptionPlanDto,
}): CancelablePromise<SubscriptionPlanDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/subscriptionPlan/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * update a subscription plan.
   * @returns SubscriptionPlanDto Returns the created subscription plan.
   * @throws ApiError
   */
  public updateSubscriptionPlan({
subscriptionPlanId,
requestBody,
}: {
/**
 * The id of the subscription Plan.
 */
subscriptionPlanId: string,
requestBody: CreateSubscriptionPlanDto,
}): CancelablePromise<SubscriptionPlanDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/subscriptionPlan/update',
      query: {
        'subscriptionPlanId': subscriptionPlanId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Retrieve a specific subscription plan by ID.
   * @returns SubscriptionPlanDto A subscription plan object.
   * @throws ApiError
   */
  public findSubscriptionPlan({
subscriptionPlanId,
}: {
/**
 * The ID of the subscription plan to retrieve.
 */
subscriptionPlanId: string,
}): CancelablePromise<SubscriptionPlanDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/subscriptionPlan/find',
      query: {
        'subscriptionPlanId': subscriptionPlanId,
      },
      errors: {
        404: `Subscription plan not found.`,
        500: `Internal server error.`,
      },
    });
  }

  /**
   * Retrieve all subscription plans for a specific community.
   * @returns SubscriptionPlanDto A list of subscription plans.
   * @throws ApiError
   */
  public findAllSubscriptionPlans({
communityId,
}: {
/**
 * The community ID to filter subscription plans.
 */
communityId: string,
}): CancelablePromise<Array<SubscriptionPlanDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/subscriptionPlan/findAll',
      query: {
        'communityId': communityId,
      },
      errors: {
        404: `No subscription plans found for the specified community.`,
        500: `Internal server error.`,
      },
    });
  }

  /**
   * delete subscription plan.
   * @returns void 
   * @throws ApiError
   */
  public deleteSubscriptionPlan({
communityId,
requestBody,
}: {
/**
 * The id of the community.
 */
communityId: string,
requestBody: SubscriptionPlanIdDto,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/subscriptionPlan/delete',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

}
