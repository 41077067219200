/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateSubscriptionPlanDto = {
  name: string;
  cardColor: string;
  annualPrice: number;
  plan: CreateSubscriptionPlanDto.plan;
  communityId: string;
};

export namespace CreateSubscriptionPlanDto {

  export enum plan {
    PAID = 'Paid',
    FREE = 'Free',
  }


}
