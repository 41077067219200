import { Button } from '@/components/Button';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { LoadingOverlay } from '@/components/Loading';
import { Pagination } from '@/components/Pagination';
import { Column, DataTable } from '@/components/Table/DataTable';
import { page, paymentStatuses, plans, search, size, useQueryParams } from '@/hooks/useQueryParams';
import { translations } from '@/locales/messages';
import { apiClient } from '@/services/api';
import { SurveyPreviewDto } from '@billy/management-api-sdk';
import { PlusIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR, { mutate } from 'swr';

import deletePath from '@/assets/images/delete.svg';
import edit from '@/assets/images/edit.svg';
import verticalDotsPath from '@/assets/images/vertical-dots.svg';
import { Dropdown } from '@/components/Dropdown';
import { Modal } from '@/components/Modal';
import { DeleteBasicModal } from '@/components/Modal/DeleteBasicModal';
import { useRole } from '@/hooks/useRole';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const config = { page, paymentStatuses, plans, search, size };

const getPercentage = (answerCount: number, memberCount: number) => {
  return memberCount === 0 ? 0 : (Number((answerCount / memberCount).toFixed(2)) * 100).toFixed(0);
};

const getPercentageColor = (answerCount: number, memberCount: number) => {
  const percentage = Number(getPercentage(answerCount, memberCount));
  if (percentage > 100) return '#4AB1B5';
  if (percentage > 0) return '#79747E';
  return '#ED6F4C';
};

type SurveyActions = {
  onSuspend: (id: string) => void;
  onSend: (id: string) => void;
};

const getSurveyActionButton = (survey: SurveyPreviewDto, actions: SurveyActions) => {
  const { status, deletedAt } = survey;

  if (status === 'inactive' && deletedAt) {
    return (
      <Button appearance="success" onClick={actions.onSend.bind(null, survey.id)}>
        <FormattedMessage id={translations.buttons.reactivate} />
      </Button>
    );
  }

  if (status === 'active') {
    return (
      <Button appearance="deleteTertiary" onClick={actions.onSuspend.bind(null, survey.id)}>
        <FormattedMessage id={translations.buttons.surveySuspend} />
      </Button>
    );
  }

  return (
    <Button appearance="primary" onClick={actions.onSend.bind(null, survey.id)}>
      <FormattedMessage id={translations.buttons.surveySendAndActivate} />
    </Button>
  );
};

type GetColumnProps = {
  setSelectedSurvey: (survey: SurveyPreviewDto) => void;
  surveyActions: SurveyActions;
};

const getColumns = ({ setSelectedSurvey, surveyActions }: GetColumnProps): Column<SurveyPreviewDto>[] => [
  {
    accessor: 'title',
    cell: (survey: SurveyPreviewDto) => <span>{survey.title}</span>,
    header: 'Title',
    width: '40%'
  },
  {
    accessor: 'questionsNumbers',
    cell: (survey: SurveyPreviewDto) => <span>{survey.questionsNumbers}</span>,
    header: 'Questions',
    width: '10%'
  },
  {
    accessor: '',
    cell: (survey: SurveyPreviewDto) => (
      <div className="flex flex-row items-center gap-1">
        <span>
          {survey.answerCount}/{survey.memberCount}
        </span>
        <span style={{ color: getPercentageColor(survey.answerCount, survey.memberCount) }}>|</span>
        <span style={{ color: getPercentageColor(survey.answerCount, survey.memberCount) }}>
          {getPercentage(survey.answerCount, survey.memberCount)}%
        </span>
      </div>
    ),
    header: 'Responses',
    width: '10%'
  },
  {
    accessor: 'createdAt',
    cell: (survey: SurveyPreviewDto) => <span>{new Date(survey.createdAt).toLocaleDateString()}</span>,
    header: 'Date added',
    width: '10%'
  },
  {
    accessor: '',
    cell: (survey: SurveyPreviewDto) => getSurveyActionButton(survey, surveyActions),
    header: '',
    width: '10%'
  },
  {
    accessor: 'actions',
    cell: (survey: SurveyPreviewDto) => (
      <Dropdown
        appearance="filter"
        dropdownClassName="absolute mt-2 w-48 rounded-md bg-white shadow-lg z-10 border border-gray-200 right-0 top-full"
        shouldUseShadow={false}
        svgPath={verticalDotsPath}
        trigger={
          <Button appearance="filter" className="rounded-full p-2 " shouldUseShadow={false}>
            <img alt="vertical dots" className="h-5 w-5" src={verticalDotsPath} />
          </Button>
        }
      >
        <Link to={`/surveys/${survey.id}`}>
          <Button className="flex w-full items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200">
            <div className="flex items-center gap-2">
              <img alt="edit" className="h-7 w-7" src={edit} />
              <FormattedMessage id={translations.buttons.edit} />
            </div>
          </Button>
        </Link>

        <Button
          className="flex w-full items-center gap-2 py-2 px-4 text-sm hover:bg-gray-200"
          onClick={setSelectedSurvey.bind(null, survey)}
        >
          <div className="flex items-center gap-2">
            <img alt="delete" className="h-7 w-7" src={deletePath} />
            <FormattedMessage id={translations.buttons.delete} />
          </div>
        </Button>
      </Dropdown>
    ),
    header: '',
    width: '10%'
  }
];

export const SurveysPage: React.FC = () => {
  const { community } = useRole();
  const [queryParams, updateQueryParams] = useQueryParams({ config });
  const [selectedSurvey, setSelectedSurvey] = useState<SurveyPreviewDto | null>(null);
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    isLoading: surveysLoading,
    error: surveysError,
    data: surveysData
  } = useSWR(['surveys/find', queryParams], () =>
    apiClient.survey.findSurveys({
      communityId: community!.id,
      pageNumber: queryParams.page,
      pageSize: queryParams.size,
      searchQuery: queryParams.search
    })
  );

  const onDeleteSurvey = async () => {
    if (!selectedSurvey) return;

    await apiClient.survey.deleteSurvey({ communityId: community?.id, surveyId: selectedSurvey.id }).then(() => {
      mutate(
        ['surveys/find', queryParams],
        {
          ...surveysData,
          items: surveysData?.items.map((survey) => {
            if (survey.id === selectedSurvey.id) {
              return { ...survey, deletedAt: new Date(), status: 'inactive' };
            }
            return survey;
          })
        },
        false
      );
    });
  };

  const onSuspendSurvey = async (id: string) => {
    await apiClient.survey.suspendSurvey({ surveyId: id }).then(() => {
      mutate(
        ['surveys/find', queryParams],
        {
          ...surveysData,
          items: surveysData?.items.map((survey) => {
            if (survey.id === id) {
              return { ...survey, status: 'inactive' };
            }
            return survey;
          })
        },
        false
      );
    });
  };

  const onSendSurvey = async (id: string) => {
    await apiClient.survey.sendSurvey({ communityId: community?.id, surveyId: id }).then(() => {
      mutate(
        ['surveys/find', queryParams],
        {
          ...surveysData,
          items: surveysData?.items.map((survey) => {
            if (survey.id === id) {
              return { ...survey, deletedAt: null, status: 'active' };
            }
            return survey;
          })
        },
        false
      );
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between gap-6">
        <DebouncedInput
          as={SearchInput}
          className="w-72"
          initialValue={queryParams.search}
          onChange={(search: string) => updateQueryParams({ page: 1, search })}
          placeholder={intl.formatMessage({ id: translations.pages.surveys.searchSurveysBy })}
        />

        <Button
          appearance="primary"
          className="flex items-center justify-center gap-2"
          onClick={() => navigate('/surveys/create')}
        >
          <PlusIcon className="h-4 w-4" />
          <FormattedMessage id={translations.buttons.newSurvey} />
        </Button>
      </div>
      <LoadingOverlay
        {...{ error: surveysError, loading: surveysLoading }}
        className={surveysError ? 'mt-24' : undefined}
      >
        <DataTable
          columns={getColumns({
            setSelectedSurvey: (survey) => {
              setSelectedSurvey(survey);
            },
            surveyActions: {
              onSend: onSendSurvey,
              onSuspend: onSuspendSurvey
            }
          })}
          data={surveysData?.items || []}
        />
      </LoadingOverlay>
      {!!surveysData && (
        <Pagination
          className="mt-9"
          currentPage={surveysData.page.pageNumber}
          onPageChange={(page: number) => updateQueryParams({ page })}
          pageSize={surveysData.page.pageSize}
          totalItems={surveysData.page.total}
        />
      )}
      <Modal
        buttonMessage={<FormattedMessage id={translations.buttons.delete} />}
        isOpen={!!selectedSurvey}
        modal={DeleteBasicModal}
        onAction={() => null}
        onClose={setSelectedSurvey.bind(null, null)}
        onDeletion={onDeleteSurvey}
        subhead={<FormattedMessage id={translations.pages.surveys.deleteSurveyCaution} />}
        title={<FormattedMessage id={translations.pages.surveys.deleteSurvey} />}
      />
    </div>
  );
};
