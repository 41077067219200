import React from 'react';

export const Tab: React.FC<{ tabs: string[]; activeTab: string; onTabChange: (tab: string) => void }> = ({
  tabs,
  activeTab,
  onTabChange
}) => {
  return (
    <div className="flex gap-6">
      {tabs.map((tab) => (
        <div
          className={`cursor-pointer py-2 px-4 ${
            activeTab === tab
              ? 'border-b-2 border-[#ED6F4C] font-bold text-[#ED6F4C]'
              : 'border-b-2  border-[#EFF4F5] text-[#170E00]'
          }`}
          key={tab}
          onClick={() => onTabChange(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};
