import React from 'react';

import { BenefitDto, BenefitPreviewDto, SubscriptionPlanDto } from '@billy/management-api-sdk';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import univerzIcon from '@/assets/univerz-icon.png';
import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Checkbox } from '@/components/Form/Checkbox';
import { ColorInput } from '@/components/Form/ColorInput';
import { Input } from '@/components/Form/Input';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { Navbar } from '@/components/Navbar';
import { Column, DataTable } from '@/components/Table/DataTable';
import { useRole } from '@/hooks/useRole';
import { translations } from '@/locales';
import { BenefitCategoriesDto } from '@billy/management-api-sdk';

export type BenefitListData = Omit<BenefitPreviewDto, 'categoryId'> & { category?: string };
export type CreateSubscriptionPlanDto = Omit<SubscriptionPlanDto, 'id' | 'createdAt' | 'deletedAt' | 'benefits'> & {
  benefitIds: string[];
  communityId: string;
};

export const schema = yup.object({
  annualPrice: yup.number().min(0).when('selectedPlan', {
    is: 'Paid',
    otherwise: yup.number().notRequired(),
    then: yup.number().required()
  }),
  cardColor: yup.string().trim().required(),
  name: yup.string().trim().required(),
  selectedPlan: yup.string().required('You must select either a Paid or Free subscription')
});

export const SubscriptionPlansDatailsPage: React.FC = () => {
  const [selectedBenefits, setSelectedBenefits] = React.useState<BenefitDto[]>([]);
  const { subscriptionPlanId } = useParams();
  const { community } = useRole();
  const navigate = useNavigate();

  const handleCheckboxChange = (planType: SubscriptionPlanDto.plan) => {
    setSelectedPlan(planType);
  };

  const isNew = !subscriptionPlanId;

  const onSubmit = async (formData: yup.InferType<typeof schema>) => {
    const data: CreateSubscriptionPlanDto = {
      annualPrice: plan == 'Paid' ? formData.annualPrice! : 0,
      benefitIds: selectedBenefits.map((benefit) => benefit.id),
      cardColor: formData.cardColor,
      communityId: community?.id || '',
      name: formData.name,
      plan: plan == 'Free' ? SubscriptionPlanDto.plan.FREE : SubscriptionPlanDto.plan.PAID
    };

    isNew
      ? await apiClient.subscriptionPlan
          .createSubscriptionPlan({ requestBody: data })
          .then(() => navigate(`/communities/${community?.id}?tab=Account`))
      : await apiClient.subscriptionPlan
          .updateSubscriptionPlan({ requestBody: data, subscriptionPlanId })
          .then(() => navigate(`/communities/${community?.id}?tab=Account`));
  };

  const resolveCategoryIdToName = React.useCallback((categoryId: string, categories: BenefitCategoriesDto) => {
    const category = categories?.find((category) => category.id === categoryId);
    return category?.name;
  }, []);

  const { data: loadedSubscriptionPlan } = useSWR(isNew ? null : ['/subscriptionPlan/find', subscriptionPlanId], () =>
    apiClient.subscriptionPlan.findSubscriptionPlan({ subscriptionPlanId: subscriptionPlanId! })
  );

  const { data } = useSWR(['benefits/find'], () =>
    apiClient.benefit.findBenefits({
      communityId: community?.id
    })
  );

  const { data: loadedCategories } = useSWR(
    'get-categories',
    () =>
      apiClient.benefit.getBenefitCategories({
        communityId: community!.id
      }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const benefitsListData = React.useMemo(
    () =>
      data?.items?.map((benefit) => {
        return { ...benefit, category: resolveCategoryIdToName(benefit.categoryId, loadedCategories!) };
      }) ?? [],
    [data?.items, loadedCategories, resolveCategoryIdToName]
  );

  const columns: Column<BenefitListData>[] = [
    {
      accessor: 'name',
      cell: (benefit: BenefitListData) => (
        <div className={`flex items-center whitespace-nowrap py-4 text-sm text-gray-900 `}>
          <div className="h-10 w-10">
            <img alt="Benefit logo" className="h-full w-full rounded-full object-cover" src={benefit.image} />
          </div>
          <div className="ml-4">
            <p className="font-semibold">{benefit.name}</p>
            <p className="text-gray-600">{benefit.url}</p>
          </div>
        </div>
      ),
      header: 'Name'
    },
    {
      accessor: 'Category',
      cell: (benefit: BenefitListData) => (
        <div className={`whitespace-nowrap py-4 text-sm`}>
          <p className="w-max rounded-full bg-[#ED6F4C] bg-opacity-10 p-2 text-[#ED6F4C]">{benefit.category}</p>
        </div>
      ),
      header: 'Category'
    }
  ];

  const initialValues = React.useMemo(() => {
    return { ...loadedSubscriptionPlan, selectedPlan: loadedSubscriptionPlan?.plan };
  }, [loadedSubscriptionPlan]);

  const [plan, setSelectedPlan] = React.useState(initialValues.plan || '');
  const [color, setColor] = React.useState('#BDBDBD');

  React.useEffect(() => {
    if (initialValues.cardColor && initialValues.benefits) {
      setColor(initialValues.cardColor);
      setSelectedBenefits(initialValues.benefits);
    }
  }, [initialValues]);

  return (
    <Layout>
      <div className="flex items-center justify-between gap-4 py-10">
        <div className="flex items-center gap-5">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to={`/communities/${community?.id}`}>
            <FormattedMessage id={translations.pages.community.myCommunity} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <Link
            as={RouterLink}
            className="text-sm font-medium text-gray-500"
            to={`/communities/${community?.id}?tab=Account`}
          >
            <FormattedMessage id={translations.pages.account.account} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <span className="text-sm font-medium text-[#ED6F4C]">
            <FormattedMessage id={isNew ? translations.pages.account.newPlan : translations.pages.account.editPlan} />
          </span>
        </div>

        <div className="flex items-center gap-2">
          <Navbar title="" />
        </div>
      </div>

      <div className="flex w-full items-center">
        <Form className="flex w-full grow flex-col items-center" {...{ initialValues, onSubmit, schema }}>
          {({ submitting }) => (
            <>
              <div className="mx-auto flex w-full max-w-2xl flex-col justify-between">
                <h1 className="text-2xl font-bold">{isNew ? 'New Plan' : 'Edit Plan'}</h1>

                <div className="mt-8">
                  <ValidatedField
                    field={Input}
                    fieldClassName={'h-14'}
                    id="name"
                    name="name"
                    placeholder={'Plan name'}
                    readOnly={submitting}
                  />

                  <div className="mt-6 flex flex-col gap-2">
                    <p className="font-semibold">
                      <FormattedMessage id={translations.fields.membershipCard} />
                    </p>
                    <p>
                      <FormattedMessage id={translations.fields.membershipCardSuggestion} />
                    </p>
                  </div>

                  <div
                    className="bg-gradient-diagonal mt-2 h-80 w-full rounded-md bg-contain bg-no-repeat p-2"
                    style={{ backgroundColor: color }}
                  >
                    <div className="flex items-center justify-between p-4">
                      <p className="text-xl font-semibold text-white">UniverZ Community</p>
                      <div>
                        <img alt="Univerz logo" className="w-26 h-5 object-contain invert filter" src={univerzIcon} />
                        <p className="text-md mt-2 text-end font-semibold text-white">[Plan name]</p>
                      </div>
                    </div>
                  </div>

                  <ValidatedField
                    className={'mx-auto mt-5 flex w-max items-center rounded-lg bg-gray-200 py-2 px-6'}
                    field={ColorInput}
                    id="cardColor"
                    label="Select color"
                    name="cardColor"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setColor(e.target.value)}
                    readOnly={submitting}
                    type="color"
                  />

                  <div className="mt-10 flex flex-col gap-5">
                    <h1 className="text-xl font-semibold">Subscription method</h1>
                    <div className="flex gap-5">
                      <ValidatedField
                        checked={plan === 'Paid'}
                        field={Checkbox}
                        fieldClassName={'text-lg'}
                        id="Paid"
                        label="Paid subscription"
                        name="selectedPlan"
                        onChange={() => handleCheckboxChange(SubscriptionPlanDto.plan.PAID)}
                        readOnly={submitting}
                        type="checkbox"
                      />
                      <ValidatedField
                        checked={plan === 'Free'}
                        field={Checkbox}
                        fieldClassName={'text-lg'}
                        id="Free"
                        label="Free subscription"
                        name="selectedPlan"
                        onChange={() => handleCheckboxChange(SubscriptionPlanDto.plan.FREE)}
                        readOnly={submitting}
                        type="checkbox"
                      />
                    </div>

                    <ValidatedField
                      disabled={plan == 'Free'}
                      field={Input}
                      fieldClassName={'h-14'}
                      id="annualPrice"
                      name="annualPrice"
                      placeholder={'Annual price'}
                      readOnly={submitting}
                      type="number"
                    />
                  </div>
                </div>
              </div>

              <div className="my-14 mx-auto flex w-full max-w-3xl flex-col justify-between">
                <h1 className="text-xl font-semibold">Benefits</h1>
                <p>Select the benefits of this plan.</p>

                <div className="mt-5">
                  <DataTable
                    columns={columns}
                    data={benefitsListData || []}
                    initialSelecteds={initialValues.benefits}
                    onSelectionChange={(selectedItems: BenefitDto[]) => setSelectedBenefits(selectedItems)}
                    selectable
                  />
                </div>
              </div>

              <div className="mx-auto mb-10 flex w-full max-w-2xl justify-between gap-10">
                <Button
                  appearance="basicSecondary"
                  as={LoadingButton}
                  className="w-full hover:bg-gray-400 disabled:opacity-50"
                  loading={submitting}
                  onClick={() => navigate(`/communities/${community?.id}?tab=Account`)}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  appearance="primary"
                  as={LoadingButton}
                  className="w-full disabled:opacity-50"
                  loading={submitting}
                  type="submit"
                >
                  {isNew ? 'Create Plan' : 'Update Plan'}
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    </Layout>
  );
};
