/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BenefitDto } from './BenefitDto';

export type SubscriptionPlanDto = {
  id: string;
  name: string;
  cardColor: string;
  annualPrice: number;
  plan: SubscriptionPlanDto.plan;
  communityId: string;
  createdAt: string;
  deletedAt: string | null;
  benefits: Array<BenefitDto>;
};

export namespace SubscriptionPlanDto {

  export enum plan {
    PAID = 'Paid',
    FREE = 'Free',
  }


}
