import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Checkbox } from '@/components/Form/Checkbox';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { Input } from '@/components/Form/Input';
import { SearchInput } from '@/components/Form/SearchInput';
import { Select } from '@/components/Form/Select';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { LoadingButton } from '@/components/LoadingButton';
import { Pagination } from '@/components/Pagination';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { Column, DataTable } from '@/components/Table/DataTable';
import { Tab } from '@/components/TabNew';
import { useRole } from '@/hooks/useRole';
import { translations } from '@/locales';
import { apiClient } from '@/services/api';
import { GroupDto, MemberDto } from '@billy/management-api-sdk';
import { ExclamationTriangleIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import * as yup from 'yup';

const MAX_INPUT_LENGTH = 80;
const MAX_QUESTIONS = 4;
const MAX_OPTIONS_PER_QUESTION = 4;
const emptyOption = { value: '' };
const emptyQuestion = { options: [{ ...emptyOption }, { ...emptyOption }], text: '' };
const emptyAudience = { added: [], allAdded: false, removed: [] };

const audienceSchema = yup.object({
  added: yup.array().of(yup.string().required()).required(),
  allAdded: yup.boolean().required(),
  removed: yup.array().of(yup.string().required()).required()
});

export const schema = yup.object({
  audiences: yup
    .object({
      groups: audienceSchema,
      members: audienceSchema
    })
    .required(),
  frequency: yup.string().required(),
  questions: yup
    .array()
    .of(
      yup.object({
        options: yup
          .array()
          .of(yup.object({ value: yup.string().required() }))
          .required()
          .min(2)
          .max(4),
        text: yup.string().required().max(MAX_INPUT_LENGTH)
      })
    )
    .required()
    .min(1)
    .max(4),
  status: yup.string().default('pending'),
  title: yup.string().required().max(MAX_INPUT_LENGTH)
});

const InputCounter = ({ total, current }: { total: number; current: number }) => (
  <span className="self-end text-sm">
    {current}/{total}
  </span>
);

type AudienceTypes = 'members' | 'groups';
type Audience = {
  added: string[];
  removed: string[];
  allAdded: boolean;
};

type AudienceProps = {
  addAudience: (type: AudienceTypes, id: string) => void;
  removeAudience: (type: AudienceTypes, id: string) => void;
  toggleAllAudience: (type: AudienceTypes) => void;
  membersAudience: Audience;
  groupsAudience: Audience;
};

type GetAudienceColumn = {
  type: AudienceTypes;
  addAudience: (id: string) => void;
  removeAudience: (id: string) => void;
  toggleAllAudience: () => void;
  audience: Audience;
};

const getAudienceColumn = (props: GetAudienceColumn): Column<GroupDto | MemberDto>[] => {
  const columns: Column<GroupDto | MemberDto>[] = [];

  if (props.type === 'members') {
    columns.push({
      accessor: '',
      // @ts-ignore
      cell: (member: MemberDto) => (
        <div className="flex items-center gap-2 px-4 py-6">
          <img className="h-10 w-10 rounded-full" src={member.image} />
          <div className="flex flex-col">
            <span className="text-base text-[#170E00]">
              {member.firstName} {member.lastName}
            </span>
            <span className="text-sm opacity-60">{member.email}</span>
            <span className="text-sm opacity-60">{member.phone}</span>
          </div>
        </div>
      ),
      header: '',
      width: '40%'
    });
  }

  if (props.type === 'groups') {
    columns.push({
      accessor: '',
      // @ts-ignore
      cell: (group: GroupDto) => (
        <div className="flex items-center gap-4">
          <span className="bg-neutral-gray-100 text-neutral-black rounded-xl py-1 px-2">{group.name}</span>
          <span className="text-neutral-black opacity-60">
            <FormattedMessage id={translations.pages.surveys.membersByGroup} values={{ count: group.memberCount }} />
          </span>
        </div>
      ),
      header: ''
    });
  }

  columns.push({
    accessor: '',
    cell: (audience) => {
      const { added, allAdded, removed } = props.audience;

      const audienceIsAdded = (() => {
        if (removed.some((audienceRemoved) => audienceRemoved === audience.id)) return false;
        return !!added.some((audienceAdded) => audienceAdded === audience.id) || allAdded;
      })();

      const onClickAction = audienceIsAdded
        ? props.removeAudience.bind(null, audience.id)
        : props.addAudience.bind(null, audience.id);

      return (
        <div className="flex justify-end">
          <Button appearance={audienceIsAdded ? 'delete' : 'success'} onClick={onClickAction} type="button">
            <FormattedMessage id={audienceIsAdded ? translations.buttons.remove : translations.buttons.add} />
          </Button>
        </div>
      );
    },
    header: '',
    width: '5%'
  });

  return columns;
};

const Audience = (props: AudienceProps) => {
  const { community } = useRole();
  const intl = useIntl();
  const [showAudience, setShowAudience] = useState(false);
  const navigate = useNavigate();
  const [membersQueryParams, setMembersQueryParams] = useState({
    page: 1,
    search: ''
  });
  const [groupsQueryParams, setGroupsQueryParams] = useState({
    page: 1,
    search: ''
  });

  if (!community) navigate('/communities');

  const {
    isLoading: membersLoading,
    // error: membersError,
    data: membersData
  } = useSWR(['communities/find-members', { ...membersQueryParams, communityId: community!.id }], () =>
    apiClient.community.findCommunityMembers({
      communityId: community!.id,
      pageNumber: membersQueryParams.page,
      pageSize: 4,
      searchQuery: membersQueryParams.search,
      status: 'active'
    })
  );

  const {
    isLoading: groupsLoading,
    // error: groupsError,
    data: groupsData
  } = useSWR(['groups/find', { ...groupsQueryParams, communityId: community!.id }], () =>
    apiClient.group.findGroups({
      communityId: community!.id,
      pageNumber: groupsQueryParams.page,
      pageSize: 5,
      searchQuery: groupsQueryParams.search
    })
  );

  const totalOfUsersSelected = props.membersAudience.allAdded
    ? (membersData?.page.total ?? 0) - props.membersAudience.removed.length
    : props.membersAudience.added.length;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <span className="font-literata text-xl font-bold">Who will receive your survey?</span>
        <Button
          appearance="primary"
          className="flex h-12 items-center gap-2"
          onClick={() => setShowAudience(true)}
          type="button"
        >
          <PlusIcon className="h-4 w-4" />
          <span>Add audience</span>
        </Button>
      </div>
      {showAudience ? (
        <Tab.Root>
          <Tab.List>
            <Tab.Label>
              <FormattedMessage id={translations.pages.members.allMembers} />
              <span>{` (${membersData?.page.total})`}</span>
            </Tab.Label>
            <Tab.Label>
              <FormattedMessage id={translations.pages.groups.groups} />
              <span>{` (${groupsData?.page.total})`}</span>
            </Tab.Label>
            {/* TODO: adicionar tab de plans */}
            {/* <Tab.Label>Plans</Tab.Label> */}
          </Tab.List>
          <Tab.Panels>
            {/* Members Panel */}
            <Tab.Panel className="flex flex-col gap-4">
              <div className="flex justify-between">
                <DebouncedInput
                  as={SearchInput}
                  initialValue={membersQueryParams.search}
                  onChange={(value: string) => setMembersQueryParams((oldParams) => ({ ...oldParams, search: value }))}
                  placeholder={intl.formatMessage({ id: translations.pages.surveys.searchByName })}
                />
                <Button
                  appearance={props.membersAudience.allAdded ? 'deleteTertiary' : 'success'}
                  onClick={props.toggleAllAudience.bind(null, 'members')}
                  type="button"
                >
                  <FormattedMessage
                    id={
                      props.membersAudience.allAdded
                        ? translations.buttons.removeAllMembers
                        : translations.buttons.addAllMembers
                    }
                  />
                </Button>
              </div>
              <DataTable
                columns={getAudienceColumn({
                  addAudience: props.addAudience.bind(null, 'members'),
                  audience: props.membersAudience,
                  removeAudience: props.removeAudience.bind(null, 'members'),
                  toggleAllAudience: props.toggleAllAudience.bind(null, 'members'),
                  type: 'members'
                })}
                data={membersData?.items ?? []}
                isLoading={membersLoading}
                shouldShowHeader={false}
              />
              {!!membersData && (
                <Pagination
                  currentPage={membersData.page.pageNumber}
                  onPageChange={(page: number) => setMembersQueryParams((oldParams) => ({ ...oldParams, page }))}
                  pageSize={membersData.page.pageSize}
                  totalItems={membersData.page.total}
                />
              )}
              <span className="block text-center">
                <FormattedMessage
                  id={translations.pages.surveys.totalUsersSelected}
                  values={{ count: totalOfUsersSelected }}
                />
              </span>
            </Tab.Panel>
            {/* Groups panel */}
            <Tab.Panel className="flex flex-col gap-4">
              <div className="flex justify-between">
                <DebouncedInput
                  as={SearchInput}
                  initialValue={groupsQueryParams.search}
                  onChange={(value: string) => setGroupsQueryParams((oldParams) => ({ ...oldParams, search: value }))}
                  placeholder={intl.formatMessage({ id: translations.pages.surveys.searchByName })}
                />
                <Button
                  appearance={props.groupsAudience.allAdded ? 'deleteTertiary' : 'success'}
                  onClick={props.toggleAllAudience.bind(null, 'groups')}
                  type="button"
                >
                  <FormattedMessage
                    id={
                      props.groupsAudience.allAdded
                        ? translations.buttons.removeAllGroups
                        : translations.buttons.addAllGroups
                    }
                  />
                </Button>
              </div>
              <DataTable
                columns={getAudienceColumn({
                  addAudience: props.addAudience.bind(null, 'groups'),
                  audience: props.groupsAudience,
                  removeAudience: props.removeAudience.bind(null, 'groups'),
                  toggleAllAudience: props.toggleAllAudience.bind(null, 'groups'),
                  type: 'groups'
                })}
                data={groupsData?.items ?? []}
                isLoading={groupsLoading}
                shouldShowHeader={false}
              />
              {!!groupsData && (
                <Pagination
                  currentPage={groupsData.page.pageNumber}
                  onPageChange={(page: number) => setGroupsQueryParams((oldParams) => ({ ...oldParams, page }))}
                  pageSize={groupsData.page.pageSize}
                  totalItems={groupsData.page.total}
                />
              )}
              <span className="block text-center">
                <FormattedMessage
                  id={translations.pages.surveys.totalUsersSelected}
                  values={{ count: totalOfUsersSelected }}
                />
              </span>
            </Tab.Panel>
            {/* <Tab.Panel>Plans</Tab.Panel> */}
          </Tab.Panels>
        </Tab.Root>
      ) : (
        <span className="block w-full text-center text-sm text-[#79747E]">
          <FormattedMessage id={translations.pages.surveys.adviceCreateAudience} />
        </span>
      )}
    </div>
  );
};

export type CreateSurveySchemaType = yup.InferType<typeof schema>;

type CreateSurveyProps = {
  onSubmit: (values: CreateSurveySchemaType) => void;
  initialValues?: Partial<CreateSurveySchemaType>;
  onSuspend?: (id: string) => void;
  onDelete?: (id: string) => void;
};

export const FormCreateSurvey = ({ initialValues, onSubmit, onSuspend, onDelete }: CreateSurveyProps) => {
  const intl = useIntl();

  return (
    <Form
      className="mx-auto max-w-3xl"
      {...{
        initialValues: {
          audiences: {
            groups: { ...emptyAudience },
            members: { ...emptyAudience }
          },
          frequency: 'disabled',
          questions: [{ ...emptyQuestion }],
          title: '',
          ...initialValues
        },
        onSubmit,
        schema
      }}
    >
      {({ submitting, form, submitError, submitSucceeded, dirtySinceLastSubmit, values }) => {
        const shouldShowQuestionAddButton = values.questions.length < MAX_QUESTIONS;

        return (
          <div className="mx-auto flex h-full w-11/12 flex-col space-y-10">
            <div className="mx-auto flex w-3/4 items-center justify-between">
              <span className="font-literata text-left text-[28px] font-bold leading-[42px] text-[#170E00]">
                <FormattedMessage
                  id={initialValues ? translations.pages.surveys.editSurvey : translations.pages.surveys.newSurvey}
                />
              </span>
              {initialValues && onSuspend && onDelete ? (
                <div className="flex gap-6">
                  <Button appearance="delete" onClick={onSuspend} type="button">
                    <FormattedMessage id={translations.buttons.surveySuspend} />
                  </Button>
                  <Button appearance="deleteSecondary" onClick={onDelete} type="button">
                    <FormattedMessage id={translations.buttons.deleteSurvey} />
                  </Button>
                </div>
              ) : null}
            </div>

            {/* Title */}
            <div className="mx-auto flex w-3/4 flex-col gap-2">
              <ValidatedField field={Input} id="title" name="title" placeholder="Survey title" readOnly={submitting} />
              <InputCounter current={values?.title?.length ?? 0} total={MAX_INPUT_LENGTH} />
            </div>

            {/* Questions */}
            <div className="mx-auto w-3/4 space-y-10">
              <FieldArray name="questions">
                {({ fields: questionsFields }) => {
                  return questionsFields.map((questionsName, questionsIndex) => {
                    const shouldShowQuestionRemoveButton = questionsFields.length && questionsFields.length > 1;
                    const shouldShowOptionAddButton =
                      values.questions[questionsIndex].options.length < MAX_OPTIONS_PER_QUESTION;

                    return (
                      <div className="bg-neutral-gray-50 flex flex-col gap-4 rounded-2xl p-6" key={questionsName}>
                        <div className="flex justify-between">
                          <h3 className="font-literata text-xl font-bold leading-[30px]">
                            Question {questionsIndex + 1}
                          </h3>
                          {shouldShowQuestionRemoveButton && (
                            <Button
                              className="flex h-10 w-10 items-center justify-center rounded-3xl bg-[#E7625E1A] py-0 px-0"
                              onClick={() => questionsFields.remove(questionsIndex)}
                              shouldUseShadow={false}
                              type="button"
                            >
                              <TrashIcon className="h-6 w-6 stroke-[#DE3530]" />
                            </Button>
                          )}
                        </div>
                        <div className="flex flex-col gap-2">
                          <ValidatedField
                            field={Input}
                            id={`${questionsName}.text`}
                            name={`${questionsName}.text`}
                            placeholder="Untitled Question"
                            readOnly={submitting}
                          />
                          <InputCounter
                            current={values?.questions?.[questionsIndex]?.text?.length ?? 0}
                            total={MAX_INPUT_LENGTH}
                          />
                        </div>
                        <div className="flex flex-col gap-4">
                          <FieldArray name={`${questionsName}.options`}>
                            {({ fields: optionsFields }) => {
                              const shouldShowOptionRemoveButton = optionsFields.length && optionsFields.length > 2;

                              return optionsFields.map((optionsNames, optionsIndex) => (
                                <div className="flex items-center gap-4" key={optionsNames}>
                                  <Checkbox className="[&>label]:hidden" />
                                  <ValidatedField
                                    className="grow text-base"
                                    field={Input}
                                    id={`${optionsNames}.value`}
                                    name={`${optionsNames}.value`}
                                    placeholder={`Option ${optionsIndex + 1}`}
                                    readOnly={submitting}
                                  />
                                  {shouldShowOptionRemoveButton && (
                                    <Button
                                      className="border-none px-0 shadow-none"
                                      onClick={() => optionsFields.remove(optionsIndex)}
                                      type="button"
                                    >
                                      <XMarkIcon className="h-5 w-5 stroke-[#E7625E]" />
                                    </Button>
                                  )}
                                </div>
                              ));
                            }}
                          </FieldArray>
                        </div>
                        {shouldShowOptionAddButton && (
                          <div className="flex items-center gap-4">
                            <Checkbox className="[&>label]:hidden" />
                            <Button
                              className="text-brand-primary-contrast border-none px-0 text-base"
                              onClick={() => form.mutators.push(`${questionsName}.options`, '')}
                              shouldUseShadow={false}
                              type="button"
                            >
                              <FormattedMessage id={translations.buttons.addOption} />
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  });
                }}
              </FieldArray>
              {shouldShowQuestionAddButton && (
                <Button
                  className="h-28 w-full border-2 border-dashed border-[#DEDCD3]"
                  onClick={() => form.mutators.push('questions', { ...emptyQuestion })}
                  shouldUseShadow={false}
                  type="button"
                >
                  <span className="text-brand-primary-contrast text-base font-medium">
                    <FormattedMessage id={translations.buttons.addNextQuestion} />
                  </span>
                </Button>
              )}
            </div>

            {/* Audience */}
            <Audience
              addAudience={(type, id) => {
                if (values.audiences[type].allAdded)
                  form.change('audiences', {
                    ...values.audiences,
                    [type]: {
                      ...values.audiences[type],
                      removed: [...values.audiences[type].removed.filter((audienceId) => audienceId !== id)]
                    }
                  });
                else
                  form.change('audiences', {
                    ...values.audiences,
                    [type]: {
                      ...values.audiences[type],
                      added: [...values.audiences[type].added, id]
                    }
                  });
              }}
              groupsAudience={values.audiences.groups}
              membersAudience={values.audiences.members}
              removeAudience={(type, id) => {
                if (values.audiences[type].allAdded)
                  form.change('audiences', {
                    ...values.audiences,
                    [type]: {
                      ...values.audiences[type],
                      removed: [...values.audiences[type].removed, id]
                    }
                  });
                else
                  form.change('audiences', {
                    ...values.audiences,
                    [type]: {
                      ...values.audiences[type],
                      added: [...values.audiences[type].added.filter((audienceId) => audienceId !== id)]
                    }
                  });
              }}
              toggleAllAudience={(type) =>
                form.change('audiences', {
                  ...values.audiences,
                  [type]: {
                    added: [],
                    allAdded: !values.audiences[type].allAdded,
                    removed: []
                  }
                })
              }
            />

            {/* Frequency */}
            <div className="mx-auto w-3/4 space-y-6">
              <Select
                className="text-neutral-black"
                getSelectedDisplayName={({ selectedItems }) =>
                  selectedItems[0]?.name ?? intl.formatMessage({ id: translations.pages.surveys.doesNotRepeatSurvey })
                }
                items={[
                  {
                    name: intl.formatMessage({ id: translations.pages.surveys.doesNotRepeatSurvey }),
                    value: 'disabled'
                  },
                  {
                    name: intl.formatMessage({ id: translations.pages.surveys.repeatDailySurvey }),
                    value: 'daily'
                  },
                  {
                    name: intl.formatMessage({ id: translations.pages.surveys.repeatWeeklySurvey }),
                    value: 'weekly'
                  },
                  {
                    name: intl.formatMessage({ id: translations.pages.surveys.repeatMonthlySurvey }),
                    value: 'monthly'
                  },
                  {
                    name: intl.formatMessage({ id: translations.pages.surveys.repeatAnnuallySurvey }),
                    value: 'annually'
                  }
                ]}
                onChange={(value: string) => form.change('frequency', value)}
                placeholder={intl.formatMessage({ id: translations.pages.surveys.doesNotRepeatSurvey })}
                value={values.frequency}
              />

              <div className="flex items-center justify-center gap-3 bg-[#FEF7E0] p-3">
                <div className="bg-status-warning text-neutral-black h-fit w-fit rounded-full p-2 font-normal">
                  <ExclamationTriangleIcon className="stroke-neutral-white h-5 w-5 fill-none" />
                </div>
                <span className="text-base">
                  <FormattedMessage id={translations.pages.surveys.cautionCreateSurvey} />
                </span>
              </div>
              <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
              {initialValues ? (
                <div className="flex items-center justify-center">
                  <Button
                    appearance="primary"
                    className="w-3/4"
                    onClick={() => {
                      form.change('status', 'active');
                      form.submit();
                    }}
                    type="button"
                  >
                    <FormattedMessage id={translations.buttons.sendSurvey} />
                  </Button>
                </div>
              ) : (
                <>
                  <div className="flex gap-6">
                    <Button
                      as={LoadingButton}
                      className="w-full"
                      loading={submitting}
                      onClick={form.restart}
                      type="button"
                    >
                      <FormattedMessage id={translations.buttons.cancel} />
                    </Button>

                    <Button
                      appearance="primary"
                      as={LoadingButton}
                      className="w-full"
                      loading={submitting}
                      type="submit"
                    >
                      <FormattedMessage id={translations.buttons.createSurvey} />
                    </Button>
                  </div>
                  <Button
                    appearance="tertiary"
                    as={LoadingButton}
                    className="w-full hover:underline"
                    loading={submitting}
                    onClick={() => {
                      form.change('status', 'active');
                      form.submit();
                    }}
                    shouldUseShadow={false}
                  >
                    <FormattedMessage id={translations.buttons.createAndSendSurvey} />
                  </Button>
                </>
              )}
            </div>
          </div>
        );
      }}
    </Form>
  );
};
