/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSurveyDto } from '../models/CreateSurveyDto';
import type { SurveyDetailsDto } from '../models/SurveyDetailsDto';
import type { SurveyDto } from '../models/SurveyDto';
import type { SurveyPageDto } from '../models/SurveyPageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SurveyService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create a survey.
   * @returns SurveyDto Returns the created survey.
   * @throws ApiError
   */
  public createSurvey({
requestBody,
}: {
requestBody: CreateSurveyDto,
}): CancelablePromise<SurveyDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/surveys/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Delete a survey.
   * @returns void 
   * @throws ApiError
   */
  public deleteSurvey({
surveyId,
communityId,
}: {
/**
 * The id of the survey.
 */
surveyId: string,
/**
 * The id of the community.
 */
communityId?: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/surveys/delete',
      query: {
        'surveyId': surveyId,
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Suspend a survey.
   * @returns void 
   * @throws ApiError
   */
  public suspendSurvey({
surveyId,
communityId,
}: {
/**
 * The id of the survey.
 */
surveyId: string,
/**
 * The id of the community.
 */
communityId?: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/surveys/suspend',
      query: {
        'surveyId': surveyId,
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Update a survey.
   * @returns SurveyDto Returns the created survey.
   * @throws ApiError
   */
  public updateSurvey({
surveyId,
requestBody,
communityId,
}: {
/**
 * The id of the survey.
 */
surveyId: string,
requestBody: CreateSurveyDto,
/**
 * The id of the community.
 */
communityId?: string,
}): CancelablePromise<SurveyDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/surveys/update',
      query: {
        'surveyId': surveyId,
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Return a page of surveys.
   * @returns SurveyPageDto Returns a page of survey.
   * @throws ApiError
   */
  public findSurveys({
communityId,
searchQuery,
pageSize,
pageNumber,
}: {
/**
 * Id of the community.
 */
communityId?: string,
/**
 * text to search by.
 */
searchQuery?: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<SurveyPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/surveys/find',
      query: {
        'communityId': communityId,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return the details of a survey.
   * @returns SurveyDetailsDto Return the details of a survey.
   * @throws ApiError
   */
  public getSurveyDetails({
surveyId,
communityId,
}: {
/**
 * Id of the survey.
 */
surveyId: string,
/**
 * Id of the community.
 */
communityId?: string,
}): CancelablePromise<SurveyDetailsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/surveys/get-details',
      query: {
        'surveyId': surveyId,
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Send a survey.
   * @returns void 
   * @throws ApiError
   */
  public sendSurvey({
surveyId,
communityId,
}: {
/**
 * Id of the survey.
 */
surveyId: string,
/**
 * Id of the community.
 */
communityId?: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/surveys/send',
      query: {
        'surveyId': surveyId,
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

}
